/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';

import { Button, Form, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTimes } from '@fortawesome/free-solid-svg-icons';
import useTenant from 'hooks/useTenant';
import {
  TenantInputValues,
  TenantWithLogoFile,
  TenantWithLogoUrl
} from 'types/tenant';
import useResources from 'hooks/useResources';
import MultiOptionsSelect from 'components/multi-select/MultiOptionsSelect';
import { getMultipleOptionDropdownList } from 'helpers/arrayUtils';
import { Resource } from 'types/resources';
import { MultipleSelectOption } from 'types/common';

interface AddTenantPanelProps {
  open: boolean;
  onClose?: Dispatch<SetStateAction<boolean>>;
  defaultTenant?: TenantWithLogoUrl;
  showResetConfimationModal?: (val: boolean) => void;
  onResetPassword?: () => void;
  resetPasswordConfirmed?: boolean;
}

const initialTenantValues: TenantInputValues = {
  name: '',
  name_alt: '',
  logo: null,
  email: '',
  timezone: '',
  has_rate_limit: 0,
  rate_limit: 0,
  language_translation_enabled: 0,
  resources: []
};
const getInputValues = (defaultTenant: TenantWithLogoUrl) => {
  let resourceList: Resource[] = [];
  if (defaultTenant?.resources?.length) {
    resourceList = defaultTenant?.resources.map((resource: any) => {
      return {
        id: resource.id,
        name: resource?.name,
        created_at: resource?.created_at,
        updated_at: resource?.created_at
      };
    });
  }
  const resourcesOptionList = getMultipleOptionDropdownList(resourceList) || [];

  return {
    name: defaultTenant.name,
    name_alt: defaultTenant.name_alt || '',
    logo: defaultTenant.logo,
    email: defaultTenant.email,
    timezone: defaultTenant.timezone,
    has_rate_limit: defaultTenant.has_rate_limit || 0,
    language_translation_enabled:
      defaultTenant.language_translation_enabled || 0,
    rate_limit: defaultTenant.rate_limit || 0,
    resources: resourcesOptionList
  };
};
const AddTenantPanel = (props: AddTenantPanelProps) => {
  const { open, onClose, defaultTenant, showResetConfimationModal } = props;
  const { createTenant, updateTenant, resetError, error, fetchAllTenants } =
    useTenant();
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logo, setLogo] = useState<File | null>(null);
  const { resourcesOptions, fetchAllResources } = useResources();
  const [selectedTenant, setSelectedTenant] =
    useState<TenantInputValues>(initialTenantValues);

  useEffect(() => {
    if (defaultTenant) {
      setSelectedTenant(getInputValues(defaultTenant));
    } else {
      handleResetToDefault();
    }
  }, [defaultTenant]);

  useEffect(() => {
    if (!resourcesOptions?.length) {
      fetchAllResources().catch(() => {});
    }
  }, [resourcesOptions]);

  const handleClose = () => {
    if (onClose) onClose(!open);
    setIsSubmitting(false);
    setValidated(false);
    resetError();
  };
  const handleConfirmation = (val: boolean) => {
    if (showResetConfimationModal) showResetConfimationModal(val);
  };

  const handleLogoUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      setLogo(files[0]);
    } else setLogo(null);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsSubmitting(true);
    setValidated(true);

    const form = event.currentTarget;
    // const formElements = form.elements as typeof form.elements & {
    //   name: { value: string };
    //   name_alt: { value: string };
    //   email: { value: string };
    // };
    const resources = selectedTenant?.resources?.length
      ? selectedTenant.resources.map((val: MultipleSelectOption) => val.value)
      : [];
    const tenantData: TenantWithLogoFile = {
      ...selectedTenant,
      logo: logo,
      resources: JSON.stringify([...resources])
    };
    if (form.checkValidity()) {
      if (defaultTenant?.id) {
        const id = defaultTenant?.id.toString();
        if (id)
          updateTenant(tenantData, id)
            .then(() => {
              setIsSubmitting(false);

              handleClose();
            })
            .catch(() => {
              setIsSubmitting(false);
            });
      } else {
        createTenant(tenantData)
          .then(() => {
            fetchAllTenants().catch(() => {});
            setIsSubmitting(false);
            handleClose();
          })
          .catch(() => {
            setIsSubmitting(false);
          });
      }
    } else {
      setIsSubmitting(false);
    }
  };
  const handleResetToDefault = () => {
    setSelectedTenant(
      defaultTenant ? getInputValues(defaultTenant) : initialTenantValues
    );
    setIsSubmitting(false);
    setValidated(false);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    setSelectedTenant({
      ...selectedTenant,
      [key]: e.target.value
    });
  };
  const handleHasRateLimit = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedTenant({
      ...selectedTenant,
      has_rate_limit: e.target.checked ? 1 : 0
    });
    // setPolicyValues({
    //   ...policyValues,
    //   has_rate_limit: e.target.checked ? 1 : 0
    // });
  };
  const handleLanguageEnable = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedTenant({
      ...selectedTenant,
      language_translation_enabled: e.target.checked ? 1 : 0
    });
    // setPolicyValues({
    //   ...policyValues,
    //   has_rate_limit: e.target.checked ? 1 : 0
    // });
  };
  const handleRateLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setPolicyValues({
    //   ...policyValues,
    //   rate_limit: e.target.value ? parseInt(e.target.value) : 0
    // });
    setSelectedTenant({
      ...selectedTenant,
      rate_limit: e.target.value ? parseInt(e.target.value) : 0
    });
  };
  const handleResourcesSelect = (values: MultipleSelectOption[]) => {
    setSelectedTenant({
      ...selectedTenant,
      resources: values
    });
  };
  return (
    <Offcanvas
      className="settings-panel border-0"
      show={open}
      onHide={handleClose}
      placement="end"
    >
      <Offcanvas.Header className="align-items-start border-bottom border-translucent flex-column">
        <div className="pt-1 w-100 mb-6 d-flex justify-content-between align-items-start">
          <div>
            <h5 className="mb-2 me-2 lh-sm">
              {defaultTenant ? 'Edit ' : 'Add '}
              Tenant
            </h5>
            <p className="mb-0 fs-9">
              Please fill up the form and submit to{' '}
              {defaultTenant
                ? 'edit the selected tenant.'
                : 'create a new tenant.'}
            </p>
          </div>
          <button className="btn p-1 fw-bolder" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </button>
        </div>
        <Button
          variant="phoenix-secondary"
          className="w-100"
          onClick={handleResetToDefault}
        >
          <FontAwesomeIcon icon={faArrowsRotate} className="me-2 fs-10" />
          Reset to default
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-card scrollbar">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              required
              value={selectedTenant.name}
              onChange={e => handleInputChange(e as any, 'name')}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name for the tenant.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="name_alt">
            <Form.Label>Alternative name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Alternative Name"
              value={selectedTenant.name_alt}
              onChange={e => handleInputChange(e as any, 'name_alt')}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              required
              value={selectedTenant.email}
              onChange={e => handleInputChange(e as any, 'email')}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address of the tenant owner
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="logo" className="mb-3">
            <Form.Label>Upload a Logo</Form.Label>
            <Form.Control type="file" onChange={handleLogoUpload} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="timezone">
            <Form.Label>Timezone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Asia/Kathmandu"
              value={selectedTenant.timezone}
              onChange={e => handleInputChange(e as any, 'timezone')}
            />
          </Form.Group>

          <Form.Group controlId="has_rate_limit" className="mb-3">
            <Form.Check
              type="checkbox"
              id="has_rate_limit"
              label="Has Rate limit"
              onChange={handleHasRateLimit}
            />
          </Form.Group>
          <Form.Group controlId="language_translation_enabled" className="mb-3">
            <Form.Check
              type="checkbox"
              id="language_translation_enabled"
              checked={!!selectedTenant?.language_translation_enabled}
              label="Language Translation Enable"
              onChange={handleLanguageEnable}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="rate_limit"
            // title="Number of calls in 1 second"
          >
            <Form.Label>Rate Limit</Form.Label>
            <Form.Control
              type="number"
              placeholder="Rate Limit"
              required={selectedTenant?.has_rate_limit ? true : false}
              value={selectedTenant.rate_limit}
              onChange={handleRateLimitChange}
              disabled={!selectedTenant?.has_rate_limit}
              // min={100}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a rate limit for the tenant.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="resources">
            <MultiOptionsSelect
              title={'Resources'}
              options={resourcesOptions}
              onSelectChange={handleResourcesSelect}
              value={selectedTenant?.resources || []}
            />
          </Form.Group>

          {error && <div className="mb-3 small text-danger">{error}</div>}
          <div className="d-flex gap-2">
            {defaultTenant ? (
              <>
                <Button
                  disabled={isSubmitting}
                  variant="subtle-secondary"
                  onClick={() => {
                    handleConfirmation(true);
                  }}
                  className="d-flex align-items-center"
                >
                  Reset Password
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              disabled={isSubmitting}
              variant="subtle-primary"
              type="submit"
              className="d-flex align-items-center"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm me-1"></span>
              )}
              {defaultTenant ? 'Edit' : 'Add'}
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

AddTenantPanel.propTypes = {};

export default AddTenantPanel;
